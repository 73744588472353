.hire-assisto {
  &__vertical-line {
    margin: 0px 50px 0px 30px;
  }
  &__avatar-text {
    background-color: var(--color-medium-blue) !important;
    font-size: var(--font-size-sm) !important;
    font-weight: var(--font-weight-500) !important;
    cursor: pointer;
  }
  .MuiMenu-list {
    width: 200px;
    height: 113px;
  }
  .MuiMenu-paper {
    right: 1% !important;
    top: 55px !important;
  }
}

.css-1f4lqjg-MuiPaper-root-MuiPopover-paper-MuiMenu-paper {
  width: 200px !important;
  right: 20px !important;
}

.css-11b3ww9-MuiPaper-root-MuiAppBar-root {
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.08) !important;
  border: 1px solid var(--coloro-white-box-shadow) !important;
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
}

.css-1kkpk3h-MuiButtonBase-root-MuiButton-root {
  color: var(--color-black) !important;
}
.css-137ul40-MuiButtonBase-root-MuiIconButton-root:hover {
  background-color: white !important;
}
.css-5poeop {
  background-color: var(--color-white) !important;
  color: var(--color-black) !important;
  box-shadow: none !important;
}
