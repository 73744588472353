.Assisto-pagination{
    &-container{
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 16px 16px 16px 0px;
        padding: 2px;
        border-radius: 2px;
      
    }
    &-items{
        border-radius: "50%";
        margin: "0 4px";
    }
}