.error-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__error-img {
    margin: 0 auto;
    width: 40%;
  }
}

.forbidden-screen {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  &__forbidden-img {
    margin: 0 auto;
    width: 100%;
  }
}
