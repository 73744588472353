:root {
  // theme colors
  --color-primary: #202124;
  --color-secondry: #8f8d96;
  --color-actions: #ffffff;

  --color-whisper: #ececec;
  --color-dakBlue: #20264a;

  // colors

  --color-black-russian: #202124;
  --color-white: #ffffff;
  --color-black: #232323;
  --color-grey: #f4f4f4;
  --color-snow: #fbfbfb;
  --color-light-grey: #a3a3a3;
  --color-white-smoke: #e1e2ec;
  --coloro-white-box-shadow: #ececec;
  --color-medium-blue: #0055d4;
  --color-light-blue: #28abe2;
  --color-dark-purple: #20264a;
  --color-dark-red: #ff0000;
  --color-success: #149000;
  --color-text-grey: #747474;
  --color-lighter-blue: #eef5ff;
  --color-lighter-grey: #f5f5f5;

  //   font-size
  --font-size-sm: 14px;
  --font-size-normal: 16px;
  --font-size-med: 20px;
  --font-size-md: 22px;
  --font-size-medium: 32px;
  --font-size-medium-lg: 36px;
  --font-size-lg: 48px;
  --font-size-large: 64px;
  --font-size-24: 24px;
  --font-size-12: 12px;
  --font-size-18: 18px;
  --font-size-28: 28px;
  --font-size-10: 10px;
  --font-size-40: 40px;
  --font-size-21: 21px;

  //   font-styles

  --font-style-normal: normal;
  --font-style-italic: italic;

  //   font-weight
  --font-weight-400: 400;
  --font-weight-500: 500;
  --font-weight-600: 600;
  --font-weight-700: 700;
  --font-weight-900: 900;

  //  font-family
  --font-arial-helvetica: Arial, Helvetica, sans-serif;
}
