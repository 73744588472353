.table-hover:hover {
  background-color: var(--color-lighter-blue) !important;
}
.table-container {
  position: relative;
  min-width: 64px !important;

  .MuiMenu-list {
    width: 58px !important;
    height: 157px !important;
  }
  .MuiTablePagination-menu {
    position: absolute;
    top: -40px;
  }
}
