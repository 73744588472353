.hire-assisto {
  &__menubar {
    &__vertical-menu {
      position: fixed;
      height: 100vh;
      top: 8vh;
      z-index: 1000;
      width: 100px;
      flex-shrink: 0;
      padding: 1rem;
      background-color: var(--color-dakBlue);
      min-height: 100vh;
      display: "flex";
      flex-direction: column;
    }
    &__menuItems {
      color: var(--color-white) !important;
      font-size: var(--font-size-12);
      font-weight: var(--font-weight-400);
      font-family: "Poppins" !important;
      padding-top: 5px;
    }
    &__bg-white {
      background-color: var(--color-white);
      border-radius: 32px;
      height: 35px;
      width: 60px;
      color: var(--color-white);
    }
    &__text-gray {
      color: var(--color-light-grey) !important;
    }
    &__text-black {
      color: var(--color-black) !important;
    }
  }
}
