.form-file-upload {
  height: 19rem;
  max-width: 100%;
  text-align: center;
  position: relative;

  #input-file-upload {
    display: none;
  }

  .label-file-upload {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-width: 2px;
    border-radius: 1rem;
    border: 2px dashed var(--color-dark-purple) !important;

    &.drag-active {
      background-color: var(--color-white);
    }
  }

  .upload-button {
    cursor: pointer;
    padding: 0.25rem;
    font-size: 1rem;
    border: none;
    font-family: "Oswald", sans-serif;
    background-color: transparent;

    &:hover {
      text-decoration-line: underline;
    }
  }

  .drag-file-element {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
  }
}

.browse-files {
  color: var(--color-medium-blue);
  font-weight: 600;
  text-decoration: underline;
  cursor: pointer;
}
.pageScroll::-webkit-scrollbar {
  width: 4px;
}

.pageScroll::-webkit-scrollbar-track {
  background-color: #ebebeb;
  -webkit-border-radius: 10px;
  border-radius: 10px;
}

.pageScroll::-webkit-scrollbar-thumb {
  -webkit-border-radius: 10px;
  border-radius: 10px;
  background: var(--color-dark-purple);
}
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(10 * (1em + 1ex));
  display: inline-block;
}
.size-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: calc(8 * (1em + 1ex));
  display: inline-block;
}
.file-format {
  font-weight: var(--font-weight-600);
  color: var(--color-black-russian);
}
.blue-text {
  color: var(--color-medium-blue);
}
.red-text {
  color: var(--color-dark-red);
}
.progress-var {
  color: var(--color-black-russian);
  border-radius: 50px;
}
