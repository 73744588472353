.page-load-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: Translate(-50%, -50%);

  &__page-loader {
    width: 12px;
    height: 12px;
    border-radius: 50%;
    display: block;
    margin: 10px auto;
    position: relative;
    color: var(--color-light-blue);
    box-sizing: border-box;
    animation: loader-animation 2s linear infinite;
  }
}

@keyframes loader-animation {
  0% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  25% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 2px;
  }
  50% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 -2px, -14px 0 0 2px, -38px 0 0 -2px;
  }
  75% {
    box-shadow: 14px 0 0 2px, 38px 0 0 -2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
  100% {
    box-shadow: 14px 0 0 -2px, 38px 0 0 2px, -14px 0 0 -2px, -38px 0 0 -2px;
  }
}
